import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faPencilAlt,
  faTrashAlt,
  faCarrot,
} from "@fortawesome/free-solid-svg-icons";

const EditingStatus = (props) => {
  const { editing, recipeData } = props;

  return (
    <div className="pb-4 border-b  border-solid mb-4">
      {editing ? (
        <>
          <FontAwesomeIcon icon={faPencilAlt} className="mr-2  text-blue-500" />
          Osparade ändringar
        </>
      ) : recipeData.status === "published" ? (
        <>
          <FontAwesomeIcon icon={faCheck} className="mr-2  text-green-500" />
          Sparad och publicerad
        </>
      ) : recipeData.status === "trash" ? (
        <>
          <FontAwesomeIcon icon={faTrashAlt} className="mr-2  text-red-500" />I
          soptunnan
        </>
      ) : recipeData.status === "draft" ? (
        <>
          <FontAwesomeIcon
            icon={faPencilAlt}
            className="mr-2  text-yellow-500"
          />
          Sparat utkast
        </>
      ) : (
        <>
          <FontAwesomeIcon icon={faCarrot} className="mr-2  text-orange-500" />
          Nytt recept
        </>
      )}
    </div>
  );
};

export default EditingStatus;
