import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import {
  ButtonBullet,
  ButtonBulletBlue,
  ButtonBulletRed,
} from "../../../elements/Buttons";

const Ingredient = (props) => {
  const {
    ingi,
    i,
    ing,
    setNewRecipeData,
    newRecipeData,
    ingredientRef,
    setEditing,
    handleDrag,
    handleRelease,
    handleSort,
  } = props;

  const handleChange = (e, parentIndex, index) => {
    let newData = { ...newRecipeData };
    newData.ingredientList[parentIndex].ingredients[index][e.target.name] =
      e.target.value;
    setNewRecipeData({ ...newData });
    setEditing(true);
  };

  const handleDeleteIngredient = (parentIndex, index) => {
    let newData = { ...newRecipeData };
    newData.ingredientList[parentIndex].ingredients.splice(index, 1);
    setNewRecipeData({ ...newData });
  };

  const handleManualSort = (parentIndex, index, direction) => {
    const sortedItem =
      newRecipeData.ingredientList[parentIndex].ingredients[index];
    let items = newRecipeData.ingredientList[parentIndex].ingredients;
    items.splice(index, 1);
    items.splice(direction === "up" ? index - 1 : index + 1, 0, sortedItem);
    let newData = { ...newRecipeData };
    newData.ingredientList[parentIndex].ingredients = items;
    setNewRecipeData({ ...newData });
    setEditing(true);
  };

  const handleEnter = (e, index) => {
    const code = e.keyCode || e.which;
    const nextIndex = index + 1;
    if (code === 13) {
      if (e.target.name === "ingredient") {
        if (Object.keys(ingredientRef.current).length === nextIndex) {
          ingredientRef.current[
            index
          ].parentNode.nextElementSibling.nextElementSibling.firstElementChild.focus();
        } else {
          nextIngredientLine(nextIndex);
        }
      } else {
        e.target.nextSibling.focus();
      }
    }
  };

  const nextIngredientLine = (index) => {
    if (ingredientRef.current[index]) {
      ingredientRef.current[index].firstElementChild.nextSibling.focus();
    }
  };

  return (
    <li
      className="py-3 lg:py-1 flex items-center w-full relative"
      ref={(el) => (ingredientRef.current[ingi] = el)}
      onDragOver={() => handleSort(ingi)}
    >
      <div className="lg:hidden text-white inline-block w-6 foat-left -ml-2 mr-2">
        <div className="flex flex-col">
          <ButtonBulletBlue
            className="rounded-t-full rounded-b-none border-b pb-0 pt-1 h-auto"
            onClick={() => handleManualSort(i, ingi, "up")}
          >
            <FontAwesomeIcon icon={faSortUp} />
          </ButtonBulletBlue>
          <ButtonBulletBlue
            className="rounded-t-none rounded-b-full pt-0 pb-1 h-auto"
            onClick={() => handleManualSort(i, ingi, "down")}
          >
            <FontAwesomeIcon icon={faSortDown} />
          </ButtonBulletBlue>
        </div>
      </div>
      <div
        draggable
        onDragStart={(e) => handleDrag(e, i, ingi)}
        onDragEnd={() => handleRelease()}
        className="w-6 h-6 rounded-full text-sm text-white hidden lg:flex items-center justify-center cursor-grab active:cursor-grabbing bg-blue-500 mr-2 float-left"
      >
        <FontAwesomeIcon icon={faSort} />
      </div>
      <input
        className="inline bg-transparent outline-none focus:bg-gray-200 w-1/6 text-sm font-bold border-b border-dotted"
        type="number"
        name="amount"
        value={ing.amount}
        onChange={(e) => handleChange(e, i, ingi)}
        onKeyPress={(e) => handleEnter(e, ingi)}
      />
      <input
        className="bg-transparent outline-none focus:bg-gray-200 inline w-1/6 text-sm font-bold border-b border-dotted"
        type="text"
        name="unit"
        value={ing.unit}
        onChange={(e) => handleChange(e, i, ingi)}
        onKeyPress={(e) => handleEnter(e, ingi)}
      />
      <input
        className="bg-transparent outline-none focus:bg-gray-200 inline w-1/2 text-sm border-b border-dotted"
        type="text"
        name="ingredient"
        value={ing.ingredient}
        onChange={(e) => handleChange(e, i, ingi)}
        onKeyPress={(e) => handleEnter(e, ingi)}
      />

      <ButtonBulletRed
        className="ml-2 bg-gray-400  lg:hidden absolute right-0 "
        onClick={() => handleDeleteIngredient(i, ingi)}
      >
        —
      </ButtonBulletRed>
    </li>
  );
};

export default Ingredient;
