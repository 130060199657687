import React, { useState } from "react";
import FilterRecipeData from "./FilterRecipeData";
import ListRecipes from "./ListRecipes";
import { useFilteredData, useFavouriteRecipes } from "../utils/recipeFunctions";
import Loader from "../elements/Loader";

const MyFavourites = props => {
  const { user, currentUserData, recipeData } = props;
  const favouriteRecipeData = useFavouriteRecipes(recipeData, currentUserData);
  const [filter, setFilter] = useState("");
  const filteredRecipeData = useFilteredData(favouriteRecipeData, filter);

  if (!user) {
    return "Du måste vara inloggad för att ta del av dina sidor";
  }
  if (user && (!recipeData || recipeData.length < 1)) {
    return <Loader />;
  }

  return (
    <>
      <h1 className="text-3xl px-4 mb-2 underline font-serif">
        Mina favoriter
      </h1>

      {favouriteRecipeData.length < 1 ? (
        <h3 className="text-xl m-4">Inga favoriter</h3>
      ) : (
        <FilterRecipeData filter={filter} setFilter={setFilter} />
      )}
      {filteredRecipeData && (
        <ListRecipes
          currentUserData={currentUserData}
          recipeData={filteredRecipeData}
        />
      )}
    </>
  );
};

export default MyFavourites;
