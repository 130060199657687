import React, { useEffect, useState } from "react";
import firebaseApp from "./utils/firebase";
import "firebase/auth";
import withFirebaseAuth from "react-with-firebase-auth";
import * as firebase from "firebase/app";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  saveUser,
  updateUser,
  useUserRoles,
  useCurrentUserData,
  useRecipeData,
  useUserData,
} from "./utils/firestoreFunctions";
import Header from "./components/Header";
import Home from "./components/Home";
import Categories from "./components/Categories";
import SingleRecipe from "./components/SingleRecipe";
import EditRecipe from "./components/EditRecipe";
import MyRecipes from "./components/MyRecipes";
import UserPage from "./components/UserPage";
import MyFavourites from "./components/MyFavourites";
import Footer from "./components/Footer";
import UserSettings from "./components/UserSettings";
import ListAllRecipes from "./components/ListAllRecipes";
import DiscoverRecipes from "./components/DiscoverRecipes";
import Loader from "./elements/Loader";

const firebaseAppAuth = firebaseApp.auth();
const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

const App = (props) => {
  const { user, signOut, signInWithGoogle } = props;
  const userData = useUserData();
  const currentUserData = useCurrentUserData(user);
  const userRoles = useUserRoles(user);
  const recipeData = useRecipeData();
  const [filterValue, setFilterValue] = useState("");

  useEffect(() => {
    let mounted = true;
    if (props.user) {
      if (mounted) {
        saveUser(props.user);
      }
    }
    return () => (mounted = false);
  }, [props, props.user]);

  const userCanEdit = (receptUid) => {
    let mounted = true;
    if (!user || !currentUserData || !userRoles) {
      return false;
    }

    if (mounted) {
      if (
        !receptUid &&
        user &&
        currentUserData &&
        userRoles &&
        (userRoles.canEdit === true || userRoles.isAdmin === true)
      ) {
        return true;
      }
      if (
        receptUid &&
        user &&
        currentUserData &&
        userRoles &&
        ((userRoles.canEdit === true && currentUserData.uid === receptUid) ||
          userRoles.isAdmin === true)
      ) {
        return true;
      }
      return false;
    }

    return () => (mounted = false);
  };

  const handleFavourite = (id) => {
    let mounted = true;

    if (
      currentUserData &&
      currentUserData.favourites &&
      currentUserData.favourites.includes(id)
    ) {
      const filteredFavourites = currentUserData.favourites.filter(
        (fav) => fav !== id
      );
      let newUserData = currentUserData;
      newUserData.favourites = filteredFavourites;
      if (mounted) {
        updateUser(user, newUserData);
      }
    } else if (currentUserData && currentUserData.favourites) {
      let newUserData = currentUserData;
      newUserData.favourites = [...newUserData.favourites, id];
      if (mounted) {
        updateUser(user, newUserData);
      }
    } else if (currentUserData && currentUserData.favourites === undefined) {
      let newUserData = currentUserData;
      newUserData.favourites = [id];
      if (mounted) {
        updateUser(user, newUserData);
      }
    }

    return () => (mounted = false);
  };

  return (
    <Router>
      <div>
        <Header
          onSignInWithGoogle={signInWithGoogle}
          onSignOut={signOut}
          user={user}
          userCanEdit={userCanEdit}
        />
        <main id="main" className=" py-6 container mx-auto">
          {!recipeData || recipeData.length < 1 ? (
            <Loader />
          ) : (
            <div className="flex flex-col lg:flex-row">
              <Switch>
                <Route exact path="/">
                  <Home recipeData={recipeData} size="full">
                    <DiscoverRecipes
                      setFilterValue={setFilterValue}
                      recipeData={recipeData}
                      userData={userData}
                      currentUserData={currentUserData}
                    />
                  </Home>
                </Route>
                <Route exact path="/alla">
                  <Home
                    {...props}
                    recipeData={recipeData}
                    children={
                      <ListAllRecipes
                        filterValue={filterValue}
                        recipeData={recipeData}
                        currentUserData={currentUserData}
                        {...props}
                      />
                    }
                  />
                </Route>
                <Route
                  path="/kategori/:cat"
                  currentUserData={currentUserData}
                  recipeData={recipeData}
                  render={(props) => (
                    <Home
                      {...props}
                      recipeData={recipeData}
                      children={
                        <Categories
                          recipeData={recipeData}
                          currentUserData={currentUserData}
                          {...props}
                        />
                      }
                    />
                  )}
                />
                <Route
                  exact
                  path="/kategori"
                  currentUserData={currentUserData}
                  render={(props) => (
                    <Home
                      {...props}
                      recipeData={recipeData}
                      children={
                        <Categories
                          recipeData={recipeData}
                          currentUserData={currentUserData}
                          {...props}
                        />
                      }
                    />
                  )}
                />
                <Route
                  path="/user/recept"
                  userCanEdit={userCanEdit}
                  render={(props) => (
                    <UserPage
                      {...props}
                      userCanEdit={userCanEdit}
                      user={user}
                      currentUserData={currentUserData}
                      recipeData={recipeData}
                      children={
                        <MyRecipes
                          userCanEdit={userCanEdit}
                          currentUserData={currentUserData}
                          user={user}
                        />
                      }
                    />
                  )}
                />
                <Route
                  path="/user/favoriter"
                  userCanEdit={userCanEdit}
                  render={(props) => (
                    <UserPage
                      {...props}
                      userCanEdit={userCanEdit}
                      user={user}
                      currentUserData={currentUserData}
                      recipeData={recipeData}
                      children={
                        <MyFavourites
                          user={user}
                          userCanEdit={userCanEdit}
                          recipeData={recipeData}
                          currentUserData={currentUserData}
                        />
                      }
                    />
                  )}
                />
                <Route
                  exact
                  path="/user/settings"
                  render={(props) => (
                    <UserPage
                      {...props}
                      userCanEdit={userCanEdit}
                      user={user}
                      currentUserData={currentUserData}
                      recipeData={recipeData}
                      children={
                        <UserSettings
                          user={user}
                          currentUserData={currentUserData}
                          userRoles={userRoles}
                        />
                      }
                    />
                  )}
                />
                <Route
                  path="/user"
                  userCanEdit={userCanEdit}
                  recipeData={recipeData}
                  render={(props) => (
                    <UserPage
                      {...props}
                      user={user}
                      userCanEdit={userCanEdit}
                      recipeData={recipeData}
                      currentUserData={currentUserData}
                    />
                  )}
                />
                <Route
                  path="/recept/:id"
                  render={(props) => (
                    <SingleRecipe
                      {...props}
                      recipeData={recipeData}
                      userData={userData}
                      handleFavourite={handleFavourite}
                      user={user}
                      currentUserData={currentUserData}
                      userCanEdit={userCanEdit}
                    />
                  )}
                />

                <Route
                  path="/edit/:id"
                  render={(props) => (
                    <EditRecipe
                      recipeData={recipeData}
                      userCanEdit={userCanEdit}
                      {...props}
                      user={user}
                    />
                  )}
                />
                {/* <Route
                  exact
                  path="/edit"
                  render={(props) => (
                    <EditRecipe
                      userCanEdit={userCanEdit}
                      {...props}
                      user={user}
                    />
                  )}
                /> */}
              </Switch>
            </div>
          )}
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(App);
