import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { ButtonGreen } from "../elements/Buttons";
import { updateUser } from "../utils/firestoreFunctions";
import AddProfileImage from "./AddProfileImage";
import userPlaceholder from "../user-placeholder.png";

const UserSettings = (props) => {
  const { user, userRoles, currentUserData } = props;
  const [newUserData, setNewUserData] = useState({});
  const [isSaved, setIsSaved] = useState(true);

  useEffect(() => {
    let mounted = true;
    if (!currentUserData.imageUrl) {
      if (mounted) {
        setNewUserData({ ...currentUserData, imageUrl: user.photoURL });
        setIsSaved(false);
      }
    } else {
      if (mounted) {
        setNewUserData(currentUserData);
      }
    }
    return () => (mounted = false);
  }, [currentUserData, user.photoURL]);

  const handleChange = (e) => {
    setNewUserData({
      ...newUserData,
      [e.target.name]: e.target.value,
    });
    setIsSaved(false);
  };

  const handleSave = () => {
    if (!isSaved) {
      updateUser(user, newUserData);
      setIsSaved(true);
    }
  };

  if (!user) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <h1 className="capitalize text-3xl px-4 mb-2 underline font-serif">
        Användarinställningar
      </h1>

      <div className="w-full px-4">
        <ul className="text-sm mb-8">
          <label className="strong text-xl mb-2">Kontoinformation</label>
          <li className="mb-2">
            <strong>Kontonamn:</strong>{" "}
            <span className="text-gray-600 ">{currentUserData.name}</span>
          </li>
          <li className="mb-2">
            <strong>E-post:</strong>{" "}
            <span className="text-gray-600 ">{currentUserData.email}</span>
          </li>
          <li className="mb-2">
            <strong>Behörighet:</strong>{" "}
            <span className="text-gray-600 ">
              {userRoles && userRoles.isAdmin
                ? "Administratör"
                : userRoles && userRoles.canEdit
                ? "Redaktör"
                : "Besökare"}
            </span>
          </li>
          <li className="text-xs mt-2">
            Kontonamn och e-post är knutet till ditt google-konto, och kan ej
            ändras här. För att ändra behörighet, kontakta administratör.
          </li>
        </ul>

        <div className="flex flex-col w-full relative py-4 px-4 bg-white rounded shadow">
          <div className="w-full px-2 mb-2">
            <label className="strong text-xl">Publik information</label>
          </div>
          <div className="w-full flex flex-row">
            <div className="w-1/3 px-2 mb-4">
              <div
                className="w-full pb-full rounded-full bg-cover bg-center"
                style={{
                  backgroundImage: `url("${
                    newUserData.imageUrl
                      ? newUserData.imageUrl
                      : userPlaceholder
                  }")`,
                }}
              >
                {" "}
              </div>
            </div>
            <div className="w-2/3 px-2">
              <div className="w-full">
                <ul className="text-sm mb-8">
                  <li className="mb-2">
                    <strong>Visningsnamn:</strong>{" "}
                    <input
                      type="text"
                      name="displayName"
                      id="displayName"
                      placeholder={currentUserData.name}
                      value={newUserData.displayName || ""}
                      className="border-b border-dotted w-full text-xl font-serif bg-transparent"
                      onChange={handleChange}
                    />
                  </li>
                  <li className="mb-2">
                    <strong>Signatur:</strong>{" "}
                    <input
                      type="text"
                      placeholder="Jag hatar mat"
                      value={newUserData.signature || ""}
                      name="signature"
                      id="signature"
                      onChange={handleChange}
                      className="border-b border-dotted w-full italic bg-transparent"
                    />
                  </li>
                  <li className="mb-2">
                    <AddProfileImage
                      newUserData={newUserData}
                      setNewUserData={setNewUserData}
                      setIsSaved={setIsSaved}
                    />
                  </li>
                </ul>
                <div className="absolute bottom-0 p-4 right-0">
                  <ButtonGreen
                    onClick={handleSave}
                    className={isSaved && "opacity-50 cursor-not-allowed"}
                  >
                    {isSaved ? "Sparad" : "Spara ändringar"}
                  </ButtonGreen>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSettings;
