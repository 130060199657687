import React from "react";
import UserMenu from "./UserMenu";
import { Redirect } from "react-router-dom";

const UserPage = (props) => {
  const {
    user,
    currentUserData,
    onSignInWithGoogle,
    onSignOut,
    userCanEdit,
  } = props;

  if (!user) {
    return <Redirect to="/" />;
  }
  return (
    <>
      <div className="lg:w-1/5 w-full mb-6 px-4 lg:px-0 lg:pl-4">
        <UserMenu
          user={user}
          currentUserData={currentUserData}
          onSignOut={onSignOut}
          onSignInWithGoogle={onSignInWithGoogle}
          userCanEdit={userCanEdit}
        />
      </div>

      <div
        id="main"
        className="lg:w-4/5 w-full lg:pl-6 inline-block align-text-top"
      >
        {props.children}
      </div>
    </>
  );
};

export default UserPage;
