import React, { useState, useEffect } from "react";
import {
  addNewRecipe,
  updateRecipe,
  fetchSingleRecipe,
} from "../utils/firestoreFunctions";
import { Redirect } from "react-router-dom";
import { initialNewRecipeValues } from "../utils/recipeFunctions";
import AddRecipeImage from "./editRecipeComponents/AddRecipeImage";
import EditRecipeIngredients from "./editRecipeComponents/EditRecipeIngredients";
import EditRecipeInstructions from "./editRecipeComponents/EditRecipeInstructions";
import EditCategories from "./editRecipeComponents/EditCategories";
import EditTimeAndPortions from "./editRecipeComponents/EditTimeAndPortions";
import EditingStatus from "./editRecipeComponents/EditingStatus";
import SaveAndSubmitRecipe from "./editRecipeComponents/SaveAndSubmitRecipe";

const EditRecipe = (props) => {
  const { user, userCanEdit } = props;
  const [isSaved, setIsSaved] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [recipeId, setRecipeId] = useState();
  const [editing, setEditing] = useState(false);
  const [unsavedCat, setUnsavedCat] = useState(false);
  const [unsavedIng, setUnsavedIng] = useState({});
  const [newRecipeData, setNewRecipeData] = useState(initialNewRecipeValues());

  const handleSubmit = () => {
    let mounted = true;
    if (recipeId) {
      if (mounted) {
        updateRecipe(
          { ...newRecipeData, status: "published" },
          user,
          recipeId,
          setIsSaved
        );
      }
    } else {
      if (mounted) {
        addNewRecipe(
          { ...newRecipeData, status: "published" },
          user,
          setRecipeId,
          setIsSaved
        );
      }
    }
    return () => (mounted = false);
  };

  const handleTrash = () => {
    let mounted = true;
    if (recipeId) {
      if (mounted) {
        updateRecipe(
          { ...newRecipeData, status: "trash" },
          user,
          recipeId,
          setIsSaved
        );
      }
    } else {
      if (mounted) {
        setRecipeId("");
        setEditing(false);
        setNewRecipeData(initialNewRecipeValues);
      }
    }
    return () => (mounted = false);
  };

  const handleDelete = () => {
    let mounted = true;
    if (recipeId) {
      if (mounted) {
        updateRecipe(
          { ...newRecipeData, status: "deleted" },
          user,
          recipeId,
          setIsDeleted
        );
      }
    } else {
      if (mounted) {
        setRecipeId("");
        setEditing(false);
        setNewRecipeData(initialNewRecipeValues);
      }
    }
    return () => (mounted = false);
  };

  const handleReSubmit = () => {
    let mounted = true;
    if (recipeId) {
      if (mounted) {
        updateRecipe(
          { ...newRecipeData, status: "published" },
          user,
          recipeId,
          setIsSaved
        );
      }
    }
    return () => (mounted = false);
  };

  const handleDraft = () => {
    let mounted = true;
    if (recipeId) {
      if (mounted) {
        updateRecipe(
          { ...newRecipeData, status: "draft" },
          user,
          recipeId,
          setIsSaved
        );
      }
    } else {
      if (mounted) {
        addNewRecipe(
          { ...newRecipeData, status: "draft" },
          user,
          setRecipeId,
          setIsSaved
        );
      }
    }
    return () => (mounted = false);
  };

  useEffect(() => {
    let mounted = true;
    if (props.match && props.match.params && props.match.params.id === "new") {
      if (mounted) {
        setRecipeId("");
        setNewRecipeData(initialNewRecipeValues);
      }
    } else if (props.match && props.match.params && props.match.params.id) {
      if (mounted) {
        setRecipeId(props.match.params.id);
      }
    }
    return () => (mounted = false);
  }, [props.match]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (recipeId) {
        fetchSingleRecipe(recipeId, setNewRecipeData);
      }
    }
    return () => (mounted = false);
  }, [recipeId]);

  const handleChange = (e) => {
    const value = e.target.value;
    setNewRecipeData({
      ...newRecipeData,
      [e.target.name]: value,
    });
    setEditing(true);
  };

  if (isSaved) {
    return <Redirect to={`/recept/${recipeId}`} />;
  }
  if (isDeleted) {
    return <Redirect to="/user/recept" />;
  }
  if (
    !user ||
    !userCanEdit() ||
    (recipeId && newRecipeData && !userCanEdit(newRecipeData.createdBy))
  ) {
    setTimeout(function () {
      return <Redirect to={"/"} />;
    }, 1000);
  }
  return (
    <article id="editRecipe" className="w-full">
      <header className="w-full block mb-2 px-4">
        <input
          className="text-4xl mb-2 underline font-serif outline-none bg-transparent focus:bg-orange-100 w-full border-b border-dotted"
          type="text"
          id="title"
          name="title"
          placeholder="Namn på din maträtt"
          value={newRecipeData.title || ""}
          onChange={handleChange}
        />
        <EditCategories
          newRecipeData={newRecipeData}
          setNewRecipeData={setNewRecipeData}
          setEditing={setEditing}
          setUnsavedCat={setUnsavedCat}
        />
      </header>
      <div className="lg:w-4/5 w-full inline-block align-top px-4 mb-6">
        <AddRecipeImage
          newRecipeData={newRecipeData}
          setEditing={setEditing}
          setNewRecipeData={setNewRecipeData}
        />
        <div
          id="leftBar"
          className="w-full md:w-1/3  mb-6 inline-block align-top "
        >
          <EditTimeAndPortions
            newRecipeData={newRecipeData}
            handleChange={handleChange}
          />

          <EditRecipeIngredients
            setNewRecipeData={setNewRecipeData}
            newRecipeData={newRecipeData}
            setEditing={setEditing}
            unsavedIng={unsavedIng}
            setUnsavedIng={setUnsavedIng}
          />
        </div>
        <div
          id="info"
          className="md:w-2/3 w-full px-6 inline-block align-text-top"
        >
          <textarea
            className="w-full font-serif outline-none  text-xl h-48 note-pad-big"
            id="info"
            name="info"
            placeholder="Detta är ett familjerecept från grannens katt... Berätta lite om ditt recept här!"
            value={newRecipeData.info}
            onChange={handleChange}
          />

          <EditRecipeInstructions
            newRecipeData={newRecipeData}
            setNewRecipeData={setNewRecipeData}
            setEditing={setEditing}
          />
        </div>
        <div className="inline-block"></div>
      </div>
      <div
        id="rightBar"
        className="lg:w-1/5 w-full px-4 lg:px-0 inline-block align-top"
      >
        <div id="other" className="w-full shadow p-6 bg-gray-300 rounded">
          <EditingStatus editing={editing} recipeData={newRecipeData} />
          <SaveAndSubmitRecipe
            unsavedIng={unsavedIng}
            unsavedCat={unsavedCat}
            handleSubmit={handleSubmit}
            handleReSubmit={handleReSubmit}
            handleDraft={handleDraft}
            handleTrash={handleTrash}
            handleDelete={handleDelete}
            newRecipeData={newRecipeData}
            recipeId={recipeId}
          />
        </div>
      </div>
    </article>
  );
};

export default EditRecipe;
