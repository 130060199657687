import React from "react";
import Loader from "../elements/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faHeart } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import placeholder from "../placeholder.png";

const ListSingleRecipe = (props) => {
  const { recept, currentUserData } = props;

  if (!recept) {
    return <Loader />;
  }

  return (
    <div className="w-full mb-8">
      <Link to={`/recept/${recept.id}`}>
        <div className="w-full hover:underline inline-block text-shadow-md text-white  px-4">
          <div
            className="w-full bg-white h-full relative shadow hover:shadow-lg rounded-lg transition-shadow duration-300  overflow-hidden  p-4 bg-cover bg-center"
            style={{
              backgroundImage: `url("${
                recept.imageUrl ? recept.imageUrl : placeholder
              }")`,
            }}
          >
            <div className="h-72 bg-black opacity-25 -m-4 "></div>
            <header className="h-full p-4 -ml-4 flex flex-col items-center justify-center mb-4 absolute bottom-0 w-full text-center">
              <h2 className="text-2xl lg:text-4xl px-4 font-serif">
                {recept.title}
              </h2>
              <p className="px-4">{recept.info}</p>
            </header>

            {recept.time > 0 && (
              <div className="absolute bottom-0 text-base lg:text-lg ml-4 mb-2 block left-0">
                <FontAwesomeIcon
                  icon={faClock}
                  className="mr-2 text-orange-400"
                />
                {recept.time} min
              </div>
            )}
            {currentUserData &&
              currentUserData.favourites &&
              currentUserData.favourites.includes(recept.id) && (
                <div className="absolute bottom-0 m text-base lg:text-lg mr-3 mb-2 block right-0">
                  <FontAwesomeIcon
                    icon={faHeart}
                    className="mr-2 text-orange-400"
                  />
                </div>
              )}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ListSingleRecipe;
