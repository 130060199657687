import React from "react";
import { useCategories } from "../utils/recipeFunctions";
import { Link } from "react-router-dom";
import Loader from "../elements/Loader";

const PopularCategories = (props) => {
  const { recipeData, numberOf } = props;
  const recipeCategories = useCategories(recipeData);

  if (!recipeData) {
    return <Loader />;
  }
  return (
    <div className="w-full text-center mb-4">
      <ul className="text-xs lg:text-sm inline-block bg-white rounded  shadow py-2 px-4  ">
        {recipeCategories &&
          recipeCategories.slice(0, numberOf).map((cat, i) => (
            <li
              key={i}
              className="capitalize inline-block rounded  bg-blue-500  text-white my-1  mr-2  px-2 py-1 hover:bg-blue-400 "
            >
              <Link to={`/kategori/${cat.category}`}>
                {`${cat.category} (${cat.numberOf})`}{" "}
              </Link>
            </li>
          ))}
        <li className="capitalize inline-block rounded  bg-gray-500  text-white my-1  mr-2  px-2 py-1 hover:bg-gray-400">
          <Link to="/alla">Fler ...</Link>
        </li>
      </ul>
    </div>
  );
};

export default PopularCategories;
