import React, { useState, useEffect } from "react";
import { useFilteredData } from "../utils/recipeFunctions";
import FilterRecipeData from "./FilterRecipeData";
import ListRecipes from "./ListRecipes";
import Loader from "../elements/Loader";

const ListAllRecipes = (props) => {
  const { recipeData, currentUserData, filterValue } = props;
  const [filter, setFilter] = useState("");
  const filteredRecipeData = useFilteredData(recipeData, filter);
  useEffect(() => {
    let mounted = true;
    if (filterValue && mounted) {
      setFilter(filterValue);
    }
    return () => (mounted = false);
  }, [filterValue]);

  if (!recipeData) {
    return <Loader />;
  }
  return (
    <>
      <h1 className="text-3xl px-4 mb-2 underline font-serif">Alla recept</h1>
      <FilterRecipeData filter={filter} setFilter={setFilter} />

      <ListRecipes
        currentUserData={currentUserData}
        recipeData={filteredRecipeData}
      />
    </>
  );
};

export default ListAllRecipes;
