import React, { useState } from "react";
import { Button } from "../elements/Buttons";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const SearchRecipe = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchSubmitted, setSearchSubmitted] = useState(false);
  const { setFilterValue } = props;
  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterValue(searchValue);
    setSearchSubmitted(true);
  };
  if (searchSubmitted) {
    return <Redirect to="/alla" />;
  }
  return (
    <div className="px-4">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={searchValue}
          placeholder="Sök ..."
          className="w-full float-left outline-none focus:bg-white bg-gray-300 focus:shadow border-gray-399 px-4 border leading-8 rounded-full text-sm"
          onChange={handleChange}
        />
        <Button type="submit" className="-ml-10">
          <FontAwesomeIcon
            icon={faSearch}
            className="text-gray-500 hover:text-green-500"
          />
        </Button>
      </form>
    </div>
  );
};

export default SearchRecipe;
