import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faUtensils } from "@fortawesome/free-solid-svg-icons";

const EditTimeAndPortions = (props) => {
  const { newRecipeData, handleChange } = props;

  return (
    <ul className="mb-4">
      <li className="inline mr-4">
        <FontAwesomeIcon
          className="text-orange-500 text-2xl mr-2 align-middle"
          icon={faClock}
        />
        <input
          type="number"
          name="time"
          id="time"
          onChange={handleChange}
          className="w-10 border-b outline-none  bg-transparent focus:bg-orange-100 font-bold border-dotted"
          value={newRecipeData.time || ""}
          placeholder="30"
        />{" "}
        min
      </li>

      <li className="inline">
        <FontAwesomeIcon
          className="text-orange-500 text-2xl mr-2 align-middle"
          icon={faUtensils}
        />
        <input
          type="text"
          name="portions"
          id="portions"
          onChange={handleChange}
          className="w-12 border-b outline-none bg-transparent focus:bg-orange-100 font-bold border-dotted"
          value={newRecipeData.portions || ""}
          placeholder="4-6"
        />
      </li>
    </ul>
  );
};

export default EditTimeAndPortions;
