import React from "react";
import { useSingleRecipe } from "../utils/recipeFunctions";
import { Link, Redirect } from "react-router-dom";
import { ButtonBlue } from "../elements/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faHeart } from "@fortawesome/free-regular-svg-icons";
import {
  faUtensils,
  faHeart as faHeartSolid,
  faTrashAlt,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useImageUrl } from "../utils/recipeFunctions";
import Loader from "../elements/Loader";
import userPlaceholder from "../user-placeholder.png";

const SingleRecipe = (props) => {
  const {
    userCanEdit,
    user,
    currentUserData,
    recipeData,
    handleFavourite,
    userData,
  } = props;
  const { id } = props.match.params;
  const singleRecipeData = useSingleRecipe(recipeData, id, user);
  const imageUrl = useImageUrl(singleRecipeData);
  const backgroundImage = {
    backgroundImage: "url(" + imageUrl + ")",
  };

  if (!singleRecipeData && !singleRecipeData.title) {
    return <Loader />;
  }

  if (
    singleRecipeData &&
    singleRecipeData.status &&
    singleRecipeData.status !== "published" &&
    (!user ||
      !userCanEdit() ||
      (singleRecipeData && !userCanEdit(singleRecipeData.createdBy)))
  ) {
    return <Redirect to={"/"} />;
  }

  return (
    <article className="w-full" id={singleRecipeData.title}>
      <header className="w-full block mb-2 px-4">
        <h1 className="text-4xl  underline font-serif">
          {singleRecipeData.title}
        </h1>
        <div className="w-full">
          {singleRecipeData.category &&
            singleRecipeData.category.map((cat, i) => (
              <Link to={`/kategori/${cat}`} key={i}>
                <span className="bg-blue-500 inline-flex mb-2 hover:bg-blue-400 cursor-pointer text-white px-2 py-1 mr-1 uppercase text-xs rounded">
                  {cat}
                </span>
              </Link>
            ))}
        </div>
      </header>

      <div
        id="rightBar"
        className="lg:w-1/5 w-full float-right  px-4 lg:px-0 inline-block align-top"
      >
        {user && (
          <div id="other" className="w-full shadow bg-white mb-4 p-4 rounded ">
            {user && singleRecipeData.status === "published" && (
              <div
                onClick={() => handleFavourite(id)}
                className="group  text-xl  cursor-pointer  hover:underline"
              >
                {currentUserData &&
                currentUserData.favourites &&
                currentUserData.favourites.includes(id) ? (
                  <>
                    <FontAwesomeIcon
                      className="text-3xl mr-2 align-middle group-hover:hidden text-orange-500"
                      icon={faHeartSolid}
                    />
                    <FontAwesomeIcon
                      className="text-3xl mr-2 align-middle hidden group-hover:inline text-orange-500"
                      icon={faHeart}
                    />
                    <span className="group-hover:hidden">Favorit!</span>
                    <span className="hidden group-hover:inline">
                      Ta bort favorit...{" "}
                    </span>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon
                      className="text-3xl mr-2 align-middle group-hover:text-orange-500"
                      icon={faHeart}
                    />
                    Spara favorit
                  </>
                )}
              </div>
            )}
            {user && singleRecipeData.status !== "published" && (
              <div className="pb-4 border-b text-xl border-solid">
                {singleRecipeData.status === "trash" ? (
                  <>
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      className="text-3xl mr-2 align-middle text-red-500"
                    />
                    I soptunnan
                  </>
                ) : singleRecipeData.status === "draft" ? (
                  <>
                    <FontAwesomeIcon
                      icon={faPencilAlt}
                      className="text-3xl mr-2 align-middle text-yellow-500"
                    />
                    Sparat utkast
                  </>
                ) : (
                  ""
                )}
              </div>
            )}
            {userCanEdit(singleRecipeData.createdBy) && (
              <div className="pt-3 w-full mt-3 ">
                <Link to={`/edit/${id}`}>
                  <ButtonBlue>Redigera recept</ButtonBlue>
                </Link>
              </div>
            )}
          </div>
        )}
        {userData && (
          <div className="w-full mb-4 flex ">
            <div className="w-1/6">
              <div
                className="w-full pb-full rounded-full bg-cover bg-center"
                style={{
                  backgroundImage: `url("${
                    userData &&
                    userData[singleRecipeData.createdBy] &&
                    userData[singleRecipeData.createdBy].imageUrl
                      ? userData[singleRecipeData.createdBy].imageUrl
                      : userPlaceholder
                  }")`,
                }}
              >
                {" "}
              </div>
            </div>
            <div className="w-5/6 px-2">
              <ul className="text-sm mb-4">
                {userData[singleRecipeData.createdBy] && (
                  <li className="text font-serif">
                    <span className="text-xs italic text-gray-600">Av: </span>
                    {userData[singleRecipeData.createdBy].displayName
                      ? userData[singleRecipeData.createdBy].displayName
                      : userData[singleRecipeData.createdBy].name}
                  </li>
                )}
                {userData[singleRecipeData.createdBy] &&
                  userData[singleRecipeData.createdBy].signature && (
                    <li className="mb-2 italic text-xs">
                      {userData[singleRecipeData.createdBy].signature}
                    </li>
                  )}
              </ul>
            </div>
          </div>
        )}
      </div>

      <div className="lg:w-4/5 w-full inline-block align-top px-4 mb-6">
        <div
          className="w-full h-64 block bg-cover bg-center mb-6"
          style={backgroundImage}
        ></div>

        <div
          id="leftBar"
          className="w-full md:w-1/3 mb-6 inline-block align-top "
        >
          {((singleRecipeData.time && singleRecipeData.time > 0) ||
            singleRecipeData.portions) && (
            <ul className="mb-4">
              {singleRecipeData.time > 0 && (
                <li className="inline mr-4">
                  <FontAwesomeIcon
                    className="text-orange-500 text-2xl mr-2 align-middle"
                    icon={faClock}
                  />
                  <strong>{singleRecipeData.time}</strong> min
                </li>
              )}
              {singleRecipeData.portions && (
                <li className="inline">
                  <FontAwesomeIcon
                    className="text-orange-500 text-2xl mr-2 align-middle"
                    icon={faUtensils}
                  />
                  <strong>{singleRecipeData.portions}</strong>
                </li>
              )}
            </ul>
          )}
          <div id="ingredients" className="w-full bg-white shadow rounded p-4">
            <h3 className="text-2xl">Du behöver:</h3>
            {singleRecipeData.ingredientList &&
              singleRecipeData.ingredientList.map((list, i) => (
                <ul key={i} className="text-sm mb-4">
                  {list.title && (
                    <label className="text-xl">{list.title}</label>
                  )}
                  {list.ingredients &&
                    list.ingredients.map((item, i) => (
                      <li key={i} className="mb-3 leading-none">
                        {item.amount && <strong>{item.amount} </strong>}
                        {item.unit && <strong>{item.unit} </strong>}
                        {item.ingredient}
                      </li>
                    ))}
                </ul>
              ))}
          </div>
        </div>
        <div
          id="info"
          className="md:w-2/3 w-full px-6 inline-block align-text-top"
        >
          {singleRecipeData.info && (
            <p className="mb-6 font-serif text-xl">{singleRecipeData.info}</p>
          )}

          <label className="text-xl block w-full mb-4">Gör så här:</label>
          {singleRecipeData.instructions &&
            singleRecipeData.instructions.map((inst, i) => (
              <div className=" w-full" key={i}>
                <div className="table-cell align-text-top pr-6 w-14 pb-4">
                  <span className="rounded-full flex h-12 w-12 justify-center items-center bg-blue-500 text-white text-xl bold">
                    {inst.number}
                  </span>
                </div>
                <div className="table-cell align-top pb-6">
                  <p>{inst.instruction}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </article>
  );
};

export default SingleRecipe;
