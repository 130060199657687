import React, { useState } from "react";
import { ButtonBlue, ButtonRed } from "../elements/Buttons";
import { uploadImage } from "../utils/firestoreFunctions";

const AddProfileImage = (props) => {
  const [image, setImage] = useState();
  const [imageStatus, setImageStatus] = useState("0");
  const { newUserData, setNewUserData, setIsSaved } = props;

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    if (image) {
      uploadImage(image, saveImage, setImageStatus);
    }
  };

  const saveImage = (url) => {
    setNewUserData({
      ...newUserData,
      imageUrl: url,
    });
    setIsSaved(false);
  };

  const handleDeleteImage = () => {
    setNewUserData({
      ...newUserData,
      imageUrl: "",
    });
    setIsSaved(false);
  };

  return (
    <>
      {newUserData.imageUrl ? (
        <ButtonRed className=" mr-4" onClick={handleDeleteImage}>
          Ta bort bild
        </ButtonRed>
      ) : (
        <div className="my-8">
          <label
            className="cursor-pointer bg-blue-200 inline-block rounded px-4 py-2 mr-4"
            htmlFor="image"
          >
            {image && image.name ? image.name : "Välj ny bild ..."}
          </label>
          <input
            className="hidden"
            type="file"
            id="image"
            name="image"
            onChange={handleImageChange}
          />
          {image && image.name && (
            <ButtonBlue className="mr-4" onClick={handleUpload}>
              Ladda upp bild
            </ButtonBlue>
          )}
          {imageStatus === "error" ? (
            <p className="bg-red-200 inline-block float-none rounded px-4 py-2 mr-4 ">
              Något gick fel
            </p>
          ) : imageStatus === "uploading" ? (
            <p className="bg-green-200  rounded inline-block float-none px-4 py-2 mr-4">
              Laddar upp ...
            </p>
          ) : imageStatus === "complete" ? (
            <p></p>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};
export default AddProfileImage;
