import React, { useState } from "react";
import FilterRecipeData from "./FilterRecipeData";
import ListRecipes from "./ListRecipes";
import { useFilteredData } from "../utils/recipeFunctions";
import Loader from "../elements/Loader";
import { useRecipeDataByUser } from "../utils/firestoreFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPencilAlt } from "@fortawesome/free-solid-svg-icons";

const MyRecipes = (props) => {
  const { user, currentUserData } = props;
  const myRecipeData = useRecipeDataByUser(currentUserData);
  const [filter, setFilter] = useState("");
  const filteredRecipeData = useFilteredData(myRecipeData, filter);

  if (!user) {
    return "Du måste vara inloggad för att ta del av dina sidor";
  }
  if (user && myRecipeData === undefined) {
    return <Loader />;
  }
  if (user && myRecipeData.length < 1) {
    return (
      <>
        <h1 className=" text-3xl px-4 mb-2 underline font-serif">
          Mina recept
        </h1>
        <h3 className="text-xl m-4">Inga egna recept</h3>
      </>
    );
  }
  return (
    <>
      <div className="mb-6">
        <h1 className=" text-3xl px-4 mb-2 underline font-serif">
          Mina recept
        </h1>
        <FilterRecipeData filter={filter} setFilter={setFilter} />

        {filteredRecipeData && (
          <ListRecipes
            currentUserData={currentUserData}
            recipeData={filteredRecipeData.filter(
              (recept) => recept.status === "published"
            )}
          />
        )}
      </div>
      {filteredRecipeData &&
        filteredRecipeData.filter((recept) => recept.status === "draft")
          .length > 0 && (
          <div className="rounded mb-6 ml-4 p-4 shadow bg-yellow-200">
            <h3 className="text-xl ml-4 mt-2">
              <FontAwesomeIcon icon={faPencilAlt} className="mr-3" /> Mina
              utkast
            </h3>
            <ListRecipes
              currentUserData={currentUserData}
              recipeData={filteredRecipeData.filter(
                (recept) => recept.status === "draft"
              )}
            />
          </div>
        )}
      {filteredRecipeData &&
        filteredRecipeData.filter((recept) => recept.status === "trash")
          .length > 0 && (
          <div className="rounded ml-4 mb-6 p-4 shadow bg-red-200">
            <h3 className="text-xl ml-4 mt-2">
              <FontAwesomeIcon icon={faTrashAlt} className="mr-3" />
              Min soptunna
            </h3>
            <ListRecipes
              currentUserData={currentUserData}
              recipeData={filteredRecipeData.filter(
                (recept) => recept.status === "trash"
              )}
            />
          </div>
        )}
    </>
  );
};

export default MyRecipes;
