import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faClock, faHeart } from "@fortawesome/free-solid-svg-icons";
import placeholder from "../placeholder.png";
import Loader from "../elements/Loader";

const ListRecipes = (props) => {
  const { recipeData, currentUserData, header, headerIcon } = props;

  if (!recipeData) {
    return <Loader />;
  }

  return (
    <div className="w-full">
      {(header || headerIcon) && (
        <label className="w-full block px-4 -mb-4">
          {headerIcon && (
            <>
              <FontAwesomeIcon className="text-orange-500" icon={headerIcon} />{" "}
            </>
          )}
          {header && header}
        </label>
      )}
      {recipeData.map((recept, i) => (
        <Link key={i} to={`/recept/${recept.id}`}>
          <div className="w-full hover:underline sm:w-1/2 h-72  inline-block lg:w-1/3 pt-6 px-4">
            <div className="w-full bg-white h-full relative shadow hover:shadow-lg transition-shadow duration-300  overflow-hidden  rounded  p-4">
              <div
                className="bg-cover -m-4 h-40 bg-center"
                style={{
                  backgroundImage: `url("${
                    recept.imageUrl ? recept.imageUrl : placeholder
                  }")`,
                }}
              />
              <header className="mt-8 text-xl text-center font-serif">
                {recept.title}
              </header>
              {recept.time > 0 && (
                <div className="absolute bottom-0 ml-4 mb-2 block left-0 text-sm">
                  <FontAwesomeIcon
                    icon={faClock}
                    className="mr-2 text-orange-500"
                  />
                  {recept.time} min
                </div>
              )}
              {currentUserData &&
                currentUserData.favourites &&
                currentUserData.favourites.includes(recept.id) && (
                  <div className="absolute bottom-0 mr-3 mb-2 block right-0 text-sm">
                    <FontAwesomeIcon
                      icon={faHeart}
                      className="mr-2 text-orange-500"
                    />
                  </div>
                )}
            </div>
          </div>
        </Link>
      ))}
      <div className="w-full sm:w-1/2 align-bottom inline-block lg:w-1/3  px-4">
        {props.children}
      </div>
    </div>
  );
};

export default ListRecipes;
