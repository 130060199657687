import React, { useRef, useState } from "react";
import { ButtonRed, ButtonBlue } from "../../elements/Buttons";
import { initialIngredientListValues } from "../../utils/recipeFunctions";
import Ingredient from "./editRecipeIngredientsComponents/Ingredient";
import NewIngredient from "./editRecipeIngredientsComponents/NewIngredient";
import TrashCan from "./TrashCan";

const EditReciptIngredients = (props) => {
  const [draggedItem, setDraggedItem] = useState();
  const [toDelete, setToDelete] = useState(false);
  const [showTrash, setShowTrash] = useState(false);
  const [listIndex, setListIndex] = useState();
  const {
    newRecipeData,
    setNewRecipeData,
    setEditing,
    setUnsavedIng,
    unsavedIng,
  } = props;
  const ingredientRef = useRef({});
  const handleIngredientListChange = (e) => {
    let newData = { ...newRecipeData };
    const id = e.target.id.split(" ")[1];
    newData.ingredientList[id].title = e.target.value;
    setNewRecipeData({ ...newData });
    setEditing(true);
  };

  const handleTitleEnter = (e, i) => {
    const code = e.keyCode || e.which;
    if (code === 13) {
      if (
        !newRecipeData.ingredientList[i].ingredients ||
        newRecipeData.ingredientList[i].ingredients.length < 1
      ) {
        e.target.nextSibling.nextSibling.nextSibling.firstElementChild.focus();
      } else {
        e.target.nextSibling.firstElementChild.firstElementChild.nextSibling.focus();
      }
    }
  };

  const handleNewIngredientList = () => {
    let newData = { ...newRecipeData };
    newData.ingredientList.push(initialIngredientListValues());
    setNewRecipeData({ ...newData });
    setEditing(true);
  };

  const handleDeleteIngredientList = (index) => {
    let newData = newRecipeData;
    newData.ingredientList.splice(index, 1);
    setNewRecipeData({ ...newData });
    setEditing(true);
  };

  const handleDrag = (e, parentIndex, index) => {
    e.dataTransfer.setData("text/html", e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
    setListIndex(parentIndex);
    setDraggedItem(
      newRecipeData.ingredientList[parentIndex].ingredients[index]
    );
  };

  const handleRelease = () => {
    if (!draggedItem) {
      return;
    }
    if (toDelete === true) {
      const filteredIngredients = newRecipeData.ingredientList[
        listIndex
      ].ingredients.filter((item) => item !== draggedItem);

      let newData = { ...newRecipeData };
      newData.ingredientList[listIndex].ingredients = filteredIngredients;
      setNewRecipeData({ ...newData });
      setEditing(true);
    }
    setToDelete(false);
    setDraggedItem();
  };

  const handleChangeList = (index) => {
    if (!draggedItem) {
      return;
    }
    if (index === listIndex) {
      return;
    }

    let newItems = newRecipeData.ingredientList[index].ingredients
      ? newRecipeData.ingredientList[index].ingredients
      : [];
    newItems.push(draggedItem);

    let items = newRecipeData.ingredientList[listIndex].ingredients.filter(
      (item) => item !== draggedItem
    );

    let newData = { ...newRecipeData };
    newData.ingredientList[index].ingredients = newItems;
    newData.ingredientList[listIndex].ingredients = items;
    setNewRecipeData({ ...newData });
    setEditing(true);

    setListIndex(index);
  };

  const handleSort = (index) => {
    if (!draggedItem) {
      return;
    }

    const draggedOverItem =
      newRecipeData.ingredientList[listIndex].ingredients[index];

    if (draggedItem === draggedOverItem) {
      return;
    }

    let items = newRecipeData.ingredientList[listIndex].ingredients.filter(
      (item) => item !== draggedItem
    );

    items.splice(index, 0, draggedItem);

    let newData = { ...newRecipeData };
    newData.ingredientList[listIndex].ingredients = items;
    setNewRecipeData({ ...newData });
    setEditing(true);
  };

  const handlePrepareDelete = () => {
    if (!draggedItem) {
      return;
    }
    setToDelete(true);
  };
  const handleCancelDelete = () => {
    setToDelete(false);
  };

  const handleHideTrash = () => {
    setShowTrash(false);
  };

  return (
    <div id="ingredients" className="w-full bg-white shadow rounded p-4">
      <label className="text-2xl" htmlFor="ingredientList">
        Du behöver:
      </label>
      {newRecipeData.ingredientList &&
        newRecipeData.ingredientList.map((list, i) => (
          <ul
            key={i}
            className="text-sm mb-8"
            onDragOver={() => handleChangeList(i)}
          >
            <input
              className="text-xl border-b outline-none focus:bg-gray-200 border-dotted bg-transparent"
              type="text"
              id={`ingredientListTitle ${i}`}
              name="title"
              value={list.title}
              placeholder="Rubrik"
              onChange={handleIngredientListChange}
              onKeyPress={(e) => handleTitleEnter(e, i)}
            />
            <div
              onMouseOver={() => setShowTrash(true)}
              onMouseLeave={handleHideTrash}
            >
              {list.ingredients &&
                list.ingredients[0] &&
                list.ingredients.map((ing, ingi) => (
                  <Ingredient
                    key={ingi}
                    i={i}
                    ing={ing}
                    ingi={ingi}
                    newRecipeData={newRecipeData}
                    setNewRecipeData={setNewRecipeData}
                    setEditing={setEditing}
                    ingredientRef={ingredientRef}
                    handleDrag={handleDrag}
                    handleSort={handleSort}
                    handleRelease={handleRelease}
                  />
                ))}

              <TrashCan
                handleCancelDelete={handleCancelDelete}
                handlePrepareDelete={handlePrepareDelete}
                showTrash={showTrash}
                toDelete={toDelete}
                className="invisible lg:visible"
              />
            </div>
            <NewIngredient
              newRecipeData={newRecipeData}
              index={i}
              setNewRecipeData={setNewRecipeData}
              setEditing={setEditing}
              setUnsavedIng={setUnsavedIng}
              unsavedIng={unsavedIng}
            />
            <li className="pt-2 pb-4">
              <ButtonRed
                className="float-left text-xs"
                onClick={() => handleDeleteIngredientList(i)}
              >
                {" "}
                - Ta bort ovanstående lista{" "}
              </ButtonRed>
            </li>
          </ul>
        ))}
      <div className="pt-4">
        <ButtonBlue className="text-xs" onClick={handleNewIngredientList}>
          {" "}
          + Lägg till lista{" "}
        </ButtonBlue>
      </div>
    </div>
  );
};

export default EditReciptIngredients;
