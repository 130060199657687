import firebaseApp, { storage } from "./firebase";
import { useEffect, useState } from "react";
import { initialNewRecipeValues } from "./recipeFunctions";

const db = firebaseApp.firestore();

// export function fetchRecipeData(callback) {
//   db.collection("recept")
//     .where("status", "==", "published")
//     .onSnapshot(function(querySnapshot) {
//       let data = [];
//       querySnapshot.docs.forEach(function(doc) {
//         data.push({ ...doc.data(), id: doc.id });
//       });
//       callback(data);
//     });
// }

export function useRecipeData() {
  const [recipeData, setRecipeData] = useState([]);
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      db.collection("recept")
        .where("status", "==", "published")
        .onSnapshot(function (querySnapshot) {
          let data = [];
          querySnapshot.docs.forEach(function (doc) {
            data.push({ ...doc.data(), id: doc.id });
          });
          setRecipeData(data);
        });
    }
    return () => (mounted = false);
  }, []);
  return recipeData;
}
// export function useRecipeDataByCategory(category) {
//   const [recipeData, setRecipeData] = useState([]);
//   useEffect(() => {
//     let mounted = true;
//     if (category) {
//       if (mounted) {
//         db.collection("recept")
//           .where("status", "==", "published")
//           .where("category", "array-contains", category)
//           .onSnapshot(function(querySnapshot) {
//             let data = [];
//             querySnapshot.docs.forEach(function(doc) {
//               data.push({ ...doc.data(), id: doc.id });
//             });
//             setRecipeData(data);
//           });
//       }
//     }
//     return () => (mounted = false);
//   }, [category]);
//   return recipeData;
// }
// export function fetchRecipeDataByCategory(category, callback) {
//   db.collection("recept")
//     .where("status", "==", "published")
//     .where("category", "array-contains", category)
//     .onSnapshot(function(querySnapshot) {
//       let data = [];
//       querySnapshot.docs.forEach(function(doc) {
//         data.push({ ...doc.data(), id: doc.id });
//       });
//       callback(data);
//     });
// }

// export function useRecipeDataFavourites(userData) {
//   const [recipeData, setRecipeData] = useState([]);
//   useEffect(() => {
//     let mounted = true;
//     if (userData && userData.favourites) {
//       const favourites = userData.favourites;
//       if (mounted) {
//         if (!Array.isArray(favourites) || !favourites.length) {
//           console.log("NOT AN ARRAY");
//           return;
//         }

//         db.collection("recept")
//           .where("status", "==", "published")
//           .where(firebaseApp.firestore.FieldPath.documentId(), "in", favourites)
//           .onSnapshot(function(querySnapshot) {
//             let data = [];
//             querySnapshot.docs.forEach(function(doc) {
//               data.push({ ...doc.data(), id: doc.id });
//             });
//             setRecipeData(data);
//           });
//       }
//     }
//     return () => (mounted = false);
//   }, [userData]);
//   return recipeData;
// }

export function useRecipeDataByUser(user) {
  const [recipeData, setRecipeData] = useState([]);
  useEffect(() => {
    let mounted = true;
    if (user && user.uid) {
      if (mounted) {
        db.collection("recept")
          .where("createdBy", "==", user.uid)
          .get()
          .then((querySnapshot) => {
            let data = [];
            querySnapshot.docs.forEach(function (doc) {
              data.push({ ...doc.data(), id: doc.id });
            });
            setRecipeData(data);
          });
      }
    }
    return () => (mounted = false);
  }, [user]);
  return recipeData;
}

// export function useSingleRecipe(id) {
//   const [recipeData, setRecipeData] = useState({});
//   useEffect(() => {
//     let mounted = true;
//     if (mounted) {
//       db.collection("recept")
//         .doc(id)
//         .onSnapshot(function(querySnapshot) {
//           setRecipeData(querySnapshot.data());
//         });
//     }
//     return () => (mounted = false);
//   }, [id]);
//   return recipeData;
// }

export function fetchSingleRecipe(id, dataCallback) {
  db.collection("recept")
    .doc(id)
    .get()
    .then((doc) => {
      if (doc.exists) {
        dataCallback(doc.data());
      } else {
        dataCallback(initialNewRecipeValues);
      }
    });
}

export function useCurrentUserData(user) {
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    let mounted = true;

    if (mounted && user) {
      db.collection("users")
        .doc(user.uid)
        .onSnapshot(function (querySnapshot) {
          setUserData(querySnapshot.data());
        });
    }
    return () => (mounted = false);
  }, [user]);

  return userData;
}

export function useUserData() {
  const [userData, setUserData] = useState({});
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      db.collection("users").onSnapshot(function (querySnapshot) {
        const data = querySnapshot.docs
          .map((user) => user.data())
          .reduce(
            (result, value) => ({
              ...result,
              [value.uid]: value,
            }),
            {}
          );
        setUserData(data);
      });
    }
    return () => (mounted = false);
  }, []);
  return userData;
}

export function useUserRoles(user) {
  const [userRoles, setUserRoles] = useState({});
  useEffect(() => {
    let mounted = true;

    if (mounted && user) {
      db.collection("roles")
        .doc(user.uid)
        .onSnapshot(function (querySnapshot) {
          setUserRoles(querySnapshot.data());
        });
    }

    return () => (mounted = false);
  }, [user]);

  return userRoles;
}

export const uploadImage = (image, urlCallback, statusCallback) => {
  const newMetadata = { cacheControl: "public,max-age=20000" };

  const uploadTask = storage
    .ref(`images/${image.name}`)
    .put(image, newMetadata);

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      statusCallback("uploading");
    },
    (error) => {
      statusCallback("error");
    },
    () => {
      statusCallback("complete");
      storage
        .ref("images")
        .child(image.name)
        .getDownloadURL()
        .then((url) => {
          urlCallback(url);
        });
    }
  );
};

export function addNewRecipe(data, user, idCallback, statusCallback) {
  Object.keys(data).length > 0 &&
    db
      .collection("recept")
      .add({
        ...data,
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: user.uid,
        updatedBy: user.uid,
      })
      .then((res) => {
        idCallback(res.id);
        statusCallback(true);
      });
}

export function updateRecipe(data, user, docId, statusCallback) {
  Object.keys(data).length > 0 &&
    user &&
    docId &&
    db
      .collection("recept")
      .doc(docId)
      .set(
        {
          ...data,
          updatedAt: new Date(),
          updatedBy: user.uid,
        },
        { merge: true }
      )
      .then((res) => {
        statusCallback(true);
      });
}

// export function deleteRecipe(docId, statusCallback) {
//   docId &&
//     db
//       .collection("recept")
//       .doc(docId)
//       .delete()
//       .then(res => {
//         statusCallback(true);
//       });
// }

export function saveUser(user) {
  if (!user) {
    return;
  }

  db.collection("users").doc(user.uid).set(
    {
      name: user.displayName,
      uid: user.uid,
      email: user.email,
    },
    { merge: true }
  );
}

export function updateUser(user, data) {
  if (!user) {
    return;
  }

  db.collection("users")
    .doc(user.uid)
    .set(
      {
        ...data,
      },
      { merge: true }
    );
}
