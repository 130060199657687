import { Link } from "react-router-dom";
import React from "react";
import Login from "./Login";
import Loader from "../elements/Loader";

const UserMenu = (props) => {
  const {
    user,
    currentUserData,
    userCanEdit,
    onSignOut,
    onSignInWithGoogle,
  } = props;

  if (!user) {
    return (
      <Login
        onSignOut={onSignOut}
        onSignInWithGoogle={onSignInWithGoogle}
        user={user}
      />
    );
  }
  if (!user && !currentUserData) {
    return <Loader />;
  }
  return (
    <div className="w-full shadow rounded bg-white inline-block align-top px-6 py-4">
      <h3 className="text-xl lg:text-2xl">
        {currentUserData && currentUserData.name}
      </h3>
      <ul className="mb-4 text-xs lg:text-sm">
        {userCanEdit() && (
          <>
            <li className="hover:underline">
              <Link to="/edit/new">Skapa nytt recept</Link>
            </li>
            <li className="hover:underline">
              <Link to="/user/recept">Mina recept</Link>
            </li>
          </>
        )}
        <li className="hover:underline">
          <Link to="/user/favoriter">Mina favoritrecept</Link>
        </li>
        <li className="hover:underline">
          <Link to="/user/settings">Inställningar</Link>
        </li>
      </ul>
    </div>
  );
};

export default UserMenu;
