import React from "react";
import Loader from "../elements/Loader";
import ListSingleRecipe from "./ListSingleRecipe";
import ListLatestRecipes from "./ListLatestRecipes";
import ListPopularRecipes from "./ListPopularRecipes";
import PopularCategories from "./PopularCategories";
import SearchRecipe from "./SearchRecipe";

const DiscoverRecipes = (props) => {
  const { recipeData, currentUserData, userData, setFilterValue } = props;

  if (!recipeData) {
    return <Loader />;
  }

  return (
    <>
      <div className="w-full flex justify-center -mt-6">
        <div className="w-full lg:w-1/2 text-center mb-4">
          <SearchRecipe setFilterValue={setFilterValue} />
        </div>
      </div>
      <div className="w-full">
        <ListSingleRecipe
          recept={recipeData[Math.floor(Math.random() * recipeData.length)]}
          currentUserData={currentUserData}
        />
      </div>
      <div className="w-full">
        <PopularCategories recipeData={recipeData} numberOf={4} />
      </div>
      <div className="w-full mb-6 pb-6 border-b border-dotted">
        <ListLatestRecipes
          currentUserData={currentUserData}
          recipeData={recipeData}
          numberOf={3}
        />
      </div>
      <div className="w-full mb-6 pb-6 border-b border-dotted">
        <ListPopularRecipes
          currentUserData={currentUserData}
          userData={userData}
          recipeData={recipeData}
          numberOf={3}
        />
      </div>
    </>
  );
};

export default DiscoverRecipes;
