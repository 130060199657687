import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";

export const firebaseConfig = {
  apiKey: "AIzaSyCDb3FeMk09j44fhMgTv3EC2336nGXzWmo",
  authDomain: "storjala.firebaseapp.com",
  databaseURL: "https://storjala.firebaseio.com",
  projectId: "storjala",
  storageBucket: "storjala.appspot.com",
  messagingSenderId: "484335803383",
  appId: "1:484335803383:web:25e7b1d58f9a12bc4facf2",
  measurementId: "G-G48SZHS7EJ"
};
firebase.initializeApp(firebaseConfig);
export const firebaseAuth = firebase.auth();
export const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider()
};
export default firebase;

const storage = firebase.storage();
export { storage };
