import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faSignInAlt } from "@fortawesome/free-solid-svg-icons";

const Login = (props) => {
  const { user, onSignOut, onSignInWithGoogle, className } = props;
  return (
    <>
      {user ? (
        <FontAwesomeIcon
          icon={faSignOutAlt}
          onClick={onSignOut}
          className={`cursor-pointer ${className}`}
        />
      ) : (
        <FontAwesomeIcon
          icon={faSignInAlt}
          className={`cursor-pointer ${className}`}
          onClick={onSignInWithGoogle}
        />
      )}
    </>
  );
};

export default Login;
