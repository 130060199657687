import React from "react";

const Button = (props) => {
  return (
    <button
      onClick={props.onClick}
      name={props.name}
      value={props.value}
      id={props.id}
      type={props.type}
      className={`btn rounded focus:outline-none px-2 py-1 text-white ${props.className}`}
    >
      {props.children}
    </button>
  );
};

const ButtonGray = (props) => {
  return (
    <Button
      onClick={props.onClick}
      name={props.name}
      value={props.value}
      id={props.id}
      type={props.type}
      className={`bg-gray-500 hover:bg-gray-400 focus:bg-gray-600 ${props.className}`}
    >
      {props.children}
    </Button>
  );
};

const ButtonBlue = (props) => {
  return (
    <Button
      onClick={props.onClick}
      name={props.name}
      value={props.value}
      id={props.id}
      type={props.type}
      className={`bg-blue-500 hover:bg-blue-400 focus:bg-blue-600 ${props.className}`}
    >
      {props.children}
    </Button>
  );
};

const ButtonYellow = (props) => {
  return (
    <Button
      onClick={props.onClick}
      name={props.name}
      value={props.value}
      id={props.id}
      type={props.type}
      className={`bg-yellow-500 hover:bg-yellow-400 focus:bg-yellow-600 ${props.className}`}
    >
      {props.children}
    </Button>
  );
};

const ButtonRed = (props) => {
  return (
    <Button
      onClick={props.onClick}
      name={props.name}
      value={props.value}
      id={props.id}
      type={props.type}
      className={`bg-red-500 hover:bg-red-400 focus:bg-red-600 ${props.className}`}
    >
      {props.children}
    </Button>
  );
};

const ButtonGreen = (props) => {
  return (
    <Button
      onClick={props.onClick}
      name={props.name}
      value={props.value}
      id={props.id}
      type={props.type}
      className={`bg-green-500 hover:bg-green-400 focus:bg-green-600 ${props.className}`}
    >
      {props.children}
    </Button>
  );
};

const ButtonBulletRed = (props) => {
  return (
    <ButtonBullet
      onClick={props.onClick}
      name={props.name}
      value={props.value}
      id={props.id}
      type={props.type}
      className={`bg-red-500 hover:bg-red-400 focus:bg-red-600 ${props.className}`}
    >
      {props.children}
    </ButtonBullet>
  );
};

const ButtonBulletGray = (props) => {
  return (
    <ButtonBullet
      onClick={props.onClick}
      name={props.name}
      value={props.value}
      id={props.id}
      type={props.type}
      className={`bg-gray-500 hover:bg-gray-400 focus:bg-gray-600 ${props.className}`}
    >
      {props.children}
    </ButtonBullet>
  );
};

const ButtonBulletBlue = (props) => {
  return (
    <ButtonBullet
      onClick={props.onClick}
      name={props.name}
      value={props.value}
      id={props.id}
      type={props.type}
      className={`bg-blue-500 hover:bg-blue-400 focus:bg-blue-600 ${props.className}`}
    >
      {props.children}
    </ButtonBullet>
  );
};

const ButtonBulletGreen = (props) => {
  return (
    <ButtonBullet
      onClick={props.onClick}
      name={props.name}
      value={props.value}
      id={props.id}
      type={props.type}
      className={`bg-green-500 hover:bg-green-400 focus:bg-green-600 ${props.className}`}
    >
      {props.children}
    </ButtonBullet>
  );
};

const ButtonBulletYellow = (props) => {
  return (
    <ButtonBullet
      onClick={props.onClick}
      name={props.name}
      value={props.value}
      id={props.id}
      type={props.type}
      className={`bg-yellow-500 hover:bg-yellow-400 focus:bg-yellow-600 ${props.className}`}
    >
      {props.children}
    </ButtonBullet>
  );
};

const ButtonBullet = (props) => {
  return (
    <button
      onClick={props.onClick}
      name={props.name}
      value={props.value}
      id={props.id}
      type={props.type}
      className={`btn text-white font-bold align-middle focus:outline-none justify-center inline-flex items-center w-6 h-6 text-xs rounded-full ${props.className}`}
    >
      {props.children}
    </button>
  );
};

export {
  Button,
  ButtonGray,
  ButtonBlue,
  ButtonYellow,
  ButtonRed,
  ButtonGreen,
  ButtonBulletBlue,
  ButtonBulletYellow,
  ButtonBulletRed,
  ButtonBulletGreen,
  ButtonBulletGray,
  ButtonBullet,
};
