import React, { useState } from "react";
import ListRecipes from "./ListRecipes";
import Loader from "../elements/Loader";
import { ButtonGray } from "../elements/Buttons";
import { faCertificate } from "@fortawesome/free-solid-svg-icons";

const ListLatestRecipes = (props) => {
  const { numberOf, recipeData, currentUserData } = props;
  const [numberOfLatest, setNumberOfLatest] = useState(numberOf);

  const handleMore = () => {
    setNumberOfLatest(numberOfLatest + numberOf);
  };

  if (!recipeData) {
    return <Loader />;
  }
  return (
    <>
      <ListRecipes
        header="Nytt!"
        headerIcon={faCertificate}
        currentUserData={currentUserData}
        recipeData={recipeData
          .sort((a, b) => b.createdAt - a.createdAt)
          .slice(0, numberOfLatest)}
      >
        {numberOf && numberOfLatest < recipeData.length && (
          <ButtonGray
            className="mb-1 mt-6 h-8 w-auto lg:w-8  pb-1 overflow-hidden border-blue-500 px-3 lg:px-0 hover:w-auto hover:px-3  rounded-full"
            onClick={handleMore}
          >
            <strong>+</strong> Fler nya recept ...
          </ButtonGray>
        )}
      </ListRecipes>
    </>
  );
};

export default ListLatestRecipes;
