import React from "react";
import {
  ButtonGreen,
  ButtonRed,
  ButtonYellow,
  ButtonBlue,
} from "../../elements/Buttons";

const SaveAndSubmitRecipe = (props) => {
  const {
    newRecipeData,
    handleSubmit,
    handleDraft,
    handleReSubmit,
    handleTrash,
    handleDelete,
    recipeId,
    unsavedCat,
    unsavedIng,
  } = props;
  return (
    <>
      {unsavedCat && (
        <div className="bg-red-500 text-xs p-2 text-white mb-4 rounded">
          Du har börjat att skriva en kategori, men ej lagt till den. Var
          uppmärksam på att den kommer försvinna om du sparar receptet nu!
        </div>
      )}
      {Object.values(unsavedIng).includes(true) && (
        <div className="bg-red-500 text-xs p-2 text-white mb-4 rounded">
          Du har börjat att skriva en ingrediens, men ej lagt till den. Var
          uppmärksam på att den kommer försvinna om du sparar receptet nu!
        </div>
      )}
      {newRecipeData.status !== "trash" ? (
        <ButtonGreen
          className="block w-full mb-4 text-center"
          onClick={() => handleSubmit()}
          value="Spara recept"
        >
          {newRecipeData.status === "published"
            ? "Spara recept"
            : "Spara och publicera"}
        </ButtonGreen>
      ) : (
        <ButtonBlue
          className="block w-full mb-4 text-center"
          onClick={() => handleSubmit()}
          value="Spara i papperskorgen"
        >
          Spara i papperskorgen
        </ButtonBlue>
      )}
      <ButtonYellow
        className="block w-full mb-4 text-center"
        onClick={() => handleDraft()}
        value="Spara som utkast"
      >
        Spara som utkast
      </ButtonYellow>
      {newRecipeData.status === "trash" ? (
        <>
          <ButtonGreen
            className="block w-full mb-4 text-center"
            onClick={() => handleReSubmit()}
            value="Återpublicera recept"
          >
            Återpublicera recept
          </ButtonGreen>
          <ButtonRed
            className="block w-full mb-4 text-center"
            onClick={() => handleDelete()}
            value="Ta bort för evigt"
          >
            Ta bort för evigt
          </ButtonRed>
        </>
      ) : (
        <ButtonRed
          className="block w-full mb-4 text-center"
          onClick={() => handleTrash()}
          value="Kasta i papperskorgen"
        >
          {recipeId ? "Kasta i papperskorgen" : "Börja om"}
        </ButtonRed>
      )}{" "}
    </>
  );
};

export default SaveAndSubmitRecipe;
