import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const TrashCan = (props) => {
  const {
    handleCancelDelete,
    handlePrepareDelete,
    showTrash,
    toDelete,
    className,
  } = props;
  return (
    <div
      className={`${
        showTrash ? "inline" : "hidden"
      }  w-full left-0 fixed bottom-0 ${className}`}
    >
      <div className="h-2 w-full" onDragEnter={handleCancelDelete} />
      <div
        onDragEnter={handlePrepareDelete}
        className={`${
          toDelete ? "bg-red-500" : "bg-red-200"
        } text-xl text-white w-full text-center z-20 left-0  px-4 py-8`}
      >
        <FontAwesomeIcon icon={faTrashAlt} className="mr-2" /> Ta bort
      </div>
    </div>
  );
};

export default TrashCan;
