import React from "react";
import "./Loader.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPizzaSlice,
  faBlender,
  faCarrot,
  faHamburger,
  faCheese,
  faBacon,
  faDrumstickBite,
  faPepperHot,
  faLemon,
  faUtensilSpoon,
  faCookieBite,
  faHotdog,
} from "@fortawesome/free-solid-svg-icons";

const Loader = () => {
  const icon = [
    faPizzaSlice,
    faBlender,
    faCarrot,
    faHamburger,
    faCheese,
    faBacon,
    faDrumstickBite,
    faPepperHot,
    faLemon,
    faUtensilSpoon,
    faCookieBite,
    faHotdog,
  ];
  const color = [
    "text-orange-400",
    "text-blue-300",
    "text-orange-500",
    "text-orange-800",
    "text-yellow-500",
    "text-red-800",
    "text-yellow-800",
    "text-red-600",
    "text-yellow-500",
    "text-gray-500",
    "text-orange-400",
    "text-orange-700",
  ];
  const index = Math.floor(Math.random() * icon.length);
  return (
    <div className={`w-full text-center text-6xl p-8 my-8 ${color[index]}`}>
      <FontAwesomeIcon icon={icon[index]} className="spinner" />
    </div>
  );
};

export default Loader;
