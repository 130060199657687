import { useState, useEffect } from "react";
import placeholder from "../placeholder.png";
import { fetchSingleRecipe } from "./firestoreFunctions";

export const initialIngredientValues = () => {
  return {
    amount: "",
    unit: "",
    ingredient: "",
  };
};
export const initialIngredientListValues = () => {
  return {
    title: "",
  };
};
export const initialInstructionsValues = () => {
  return {
    number: 1,
    instruction: "",
  };
};

export const initialNewRecipeValues = () => {
  return {
    imageUrl: "",
    title: "",
    info: "",
    category: [],
    ingredientList: [initialIngredientListValues()],
    instructions: [initialInstructionsValues()],
    uid: "",
    saved: "",
    status: "new",
    time: "",
    portions: "",
  };
};

export function useSingleRecipe(recipeData, id, user) {
  const [singleRecipeData, setSingleRecipeData] = useState({});

  useEffect(() => {
    let mounted = true;
    if (user && id) {
      if (mounted) {
        fetchSingleRecipe(id, setSingleRecipeData);
      }
    }
    return () => (mounted = false);
  }, [user, id]);

  useEffect(() => {
    let mounted = true;
    if (!user && recipeData && id) {
      if (mounted) {
        const newData = recipeData.filter((recept) => recept.id === id);
        if (newData[0]) {
          setSingleRecipeData({ ...newData[0] });
        } else {
          setSingleRecipeData(initialNewRecipeValues);
        }
      }
    }
    return () => (mounted = false);
  }, [recipeData, id, user]);
  return singleRecipeData;
}

export function useCategoryRecipes(recipeData, category) {
  const [categoryRecipeData, setCategoryRecipeData] = useState([]);
  useEffect(() => {
    let mounted = true;
    if (recipeData && category) {
      if (mounted) {
        const newData = recipeData.filter(
          (recept) => recept.category.includes(category) === true
        );
        setCategoryRecipeData(newData);
      }
    }
    return () => (mounted = false);
  }, [recipeData, category]);

  return categoryRecipeData;
}

export function useFavouriteRecipes(recipeData, userData) {
  const [favouriteRecipeData, setFavouriteRecipeData] = useState([]);
  useEffect(() => {
    let mounted = true;
    if (recipeData && userData && userData.favourites !== undefined) {
      if (mounted) {
        let newData = [];
        recipeData.forEach(
          (recept) =>
            userData.favourites.includes(recept.id) && newData.push(recept)
        );
        setFavouriteRecipeData(newData);
      }
    }
    return () => (mounted = false);
  }, [recipeData, userData]);
  return favouriteRecipeData;
}

export function useMyRecipes(recipeData, userData) {
  const [myRecipeData, setMyRecipeData] = useState([]);
  useEffect(() => {
    let mounted = true;
    if (recipeData) {
      if (mounted) {
        const newData = recipeData.filter(
          (recept) => recept.createdBy !== userData.uid
        );
        setMyRecipeData(newData);
      }
    }
    return () => (mounted = false);
  }, [recipeData, userData]);
  return myRecipeData;
}

export function useImageUrl(recipeData) {
  const [imageUrl, setImageUrl] = useState(placeholder);

  useEffect(() => {
    let mounted = true;
    if (recipeData.imageUrl) {
      if (mounted) {
        setImageUrl(recipeData.imageUrl);
      }
    } else {
      if (mounted) {
        setImageUrl(placeholder);
      }
    }
    return () => (mounted = false);
  }, [recipeData]);
  return imageUrl;
}

export function useFilteredData(recipeData, filter) {
  const [filteredRecipeData, setFilteredRecipeData] = useState([]);
  useEffect(() => {
    let mounted = true;
    const newData = recipeData.filter(
      (recept) =>
        recept.title.toLowerCase().includes(filter.toLowerCase()) ||
        recept.category.join(", ").includes(filter.toLowerCase()) ||
        recept.ingredientList
          .map((list) =>
            list.ingredients.map((ingredient) => ingredient.ingredient)
          )
          .flat()
          .join(", ")
          .includes(filter.toLowerCase())
    );

    if (mounted) {
      setFilteredRecipeData(newData);
    }
    return () => (mounted = false);
  }, [filter, recipeData]);
  return filteredRecipeData;
}

export function useCategories(recipeData) {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (recipeData[0] && recipeData[0].category) {
        const categories = recipeData.map((recipe) => recipe.category).flat();
        const numberOfCategories = categories.reduce((result, value) => {
          result[value] ? result[value]++ : (result[value] = 1);
          return result;
        }, {});
        const sortedCategories = Array.from(new Set(categories))
          .map((cat) => ({
            category: cat,
            numberOf: numberOfCategories[cat],
          }))
          .sort((a, b) => b.numberOf - a.numberOf);
        setCategories(sortedCategories);
      }
    }
    return () => (mounted = false);
  }, [recipeData]);
  return categories;
}

export function usePopularRecipes(recipeData, userData) {
  const [popularData, setPopularData] = useState();
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (userData) {
        const favourites = Object.values(userData)
          .map((user) => user.favourites)
          .flat()
          .reduce((result, value) => {
            result[value] ? result[value]++ : (result[value] = 1);
            return result;
          }, {});

        const sortedRecipes = recipeData
          .map((recipe) => ({
            ...recipe,
            popularity:
              recipe.id && favourites[recipe.id] ? favourites[recipe.id] : 0,
          }))
          .sort((a, b) => b.popularity - a.popularity);
        setPopularData(sortedRecipes);
      }
    }
    return () => (mounted = false);
  }, [userData, recipeData]);
  return popularData;
}
