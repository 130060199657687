import React, { useState } from "react";
import ListRecipes from "./ListRecipes";
import FilterRecipeData from "./FilterRecipeData";
import { useFilteredData, useCategoryRecipes } from "../utils/recipeFunctions";
import Loader from "../elements/Loader";

const Categories = (props) => {
  const { cat } = props.match.params;
  const { currentUserData, recipeData } = props;
  const categoryRecipeData = useCategoryRecipes(recipeData, cat);
  const [filter, setFilter] = useState("");
  const filteredRecipeData = useFilteredData(categoryRecipeData, filter);

  if (!categoryRecipeData) {
    return <Loader />;
  }

  return (
    <>
      <h1 className="capitalize text-3xl px-4 mb-2 underline font-serif">
        {cat ? cat : "Ingen kategori vald"}
      </h1>
      <FilterRecipeData filter={filter} setFilter={setFilter} />
      {recipeData && (
        <ListRecipes
          currentUserData={currentUserData}
          recipeData={filteredRecipeData}
        />
      )}
    </>
  );
};

export default Categories;
