import React, { useState } from "react";
import { ButtonBlue, ButtonRed } from "../../elements/Buttons";
import placeholder from "../../placeholder.png";
import { uploadImage } from "../../utils/firestoreFunctions";

const AddRecipeImage = (props) => {
  const { newRecipeData, setEditing, setNewRecipeData } = props;
  const [imageStatus, setImageStatus] = useState("0");
  const [image, setImage] = useState();

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      setEditing(true);
    }
  };

  const handleUpload = () => {
    if (image) {
      uploadImage(image, saveImage, setImageStatus);
    }
  };

  const saveImage = (url) => {
    setNewRecipeData({
      ...newRecipeData,
      imageUrl: url,
    });
  };

  const handleDeleteImage = () => {
    setNewRecipeData({
      ...newRecipeData,
      imageUrl: "",
    });
  };
  return (
    <div
      className="w-full h-64 bg-cover bg-center text-right mb-6"
      style={{
        backgroundImage: `url("${
          newRecipeData.imageUrl ? newRecipeData.imageUrl : placeholder
        }")`,
      }}
    >
      {newRecipeData.imageUrl ? (
        <ButtonRed className=" m-4" onClick={handleDeleteImage}>
          Ta bort bild
        </ButtonRed>
      ) : (
        <>
          <label
            className="cursor-pointer bg-blue-200 inline-block rounded px-4 py-2 m-4"
            htmlFor="image"
          >
            {image && image.name ? image.name : "Välj bild ..."}
          </label>
          <input
            className="hidden"
            type="file"
            id="image"
            name="image"
            onChange={handleImageChange}
          />
          {image && image.name && (
            <ButtonBlue className="m-4" onClick={handleUpload}>
              Ladda upp bild
            </ButtonBlue>
          )}
          {imageStatus === "error" ? (
            <p className="bg-red-200 inline-block float-none rounded px-4 py-2 m-4">
              Något gick fel
            </p>
          ) : imageStatus === "uploading" ? (
            <p className="bg-green-200  rounded inline-block float-none px-4 py-2 m-4">
              Laddar upp ...
            </p>
          ) : imageStatus === "complete" ? (
            <p></p>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default AddRecipeImage;
