import React, { useState } from "react";

const EditCategories = (props) => {
  const { newRecipeData, setEditing, setNewRecipeData, setUnsavedCat } = props;
  const [newCategory, setNewCategory] = useState("");

  const handleChange = (e) => {
    setNewCategory(e.target.value.toLowerCase());
    setUnsavedCat(true);
    if (e.target.value === "") {
      setUnsavedCat(false);
    }
  };
  const handleCategorySubmit = (e) => {
    e.preventDefault();
    let newData = newRecipeData;
    newData.category.push(newCategory);
    setNewCategory("");
    setNewRecipeData({ ...newData });
    setEditing(true);
    setUnsavedCat(false);
  };
  const handleDeleteCategory = (i) => {
    let newData = newRecipeData;
    newData.category.splice(i, 1);
    setNewRecipeData({ ...newData });
    setEditing(true);
  };

  return (
    <div className="w-full">
      {" "}
      {newRecipeData.category &&
        newRecipeData.category.map((cat, i) => (
          <span
            key={i}
            className="bg-blue-500 hover:bg-red-500 inline-flex mb-2 text-white px-2 py-1 mr-1 uppercase text-xs rounded"
          >
            {cat}
            <button
              onClick={() => handleDeleteCategory(i)}
              className="font-bold ml-2 text-white"
            >
              —
            </button>
          </span>
        ))}
      <form className="inline" onSubmit={handleCategorySubmit}>
        <input
          type="text"
          name="category"
          className="bg-blue-200 focus:bg-blue-500 inline-flex mb-2 text-white outline-none pl-2 py-1 leading-1 pr-8 inline mr-1 uppercase text-xs rounded"
          onChange={handleChange}
          value={newCategory}
          placeholder="Ny kategori"
        />
        <button type="submit" className="font-bold text-white -ml-6">
          +
        </button>
      </form>
    </div>
  );
};

export default EditCategories;
