import React from "react";

const Footer = () => {
  return (
    <div className="w-full border-t bg-white mt-8 py-8">
      <div className="container mx-auto ">
        <div className="flex w-full p-4">
          <div className="w-full text-center">
            Storjalas finast receptsamling
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
