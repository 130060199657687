import React, { useState, useRef } from "react";
import { initialIngredientValues } from "../../../utils/recipeFunctions";
import { ButtonBulletGreen } from "../../../elements/Buttons";

const NewIngredient = (props) => {
  const {
    setNewRecipeData,
    setEditing,
    index,
    newRecipeData,
    setUnsavedIng,
    unsavedIng,
  } = props;
  const [newIngredient, setNewIngredient] = useState(initialIngredientValues());
  const ref = useRef();

  const handleEnter = (e) => {
    const code = e.keyCode || e.which;
    if (code === 13) {
      if (e.target.name === "ingredient") {
        if (e.target.value) {
          handleSubmit();
        }
      } else {
        e.target.nextSibling.focus();
      }
    }
  };

  const handleNewIngredient = (index, data) => {
    let newData = { ...newRecipeData };
    newData.ingredientList[index].ingredients = data;
    setNewRecipeData({ ...newData });
    setEditing(true);
  };

  const handleChange = (e) => {
    setNewIngredient({
      ...newIngredient,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "ingredient") {
      setUnsavedIng({
        ...unsavedIng,
        [index]: true,
      });
      if (e.target.value === "") {
        setUnsavedIng({
          ...unsavedIng,
          [index]: false,
        });
      }
    }
  };

  const handleSubmit = () => {
    let newArray = [];
    if (newRecipeData.ingredientList[index].ingredients) {
      newArray = [
        ...newRecipeData.ingredientList[index].ingredients,
        newIngredient,
      ];
    } else {
      newArray = [newIngredient];
    }
    handleNewIngredient(index, newArray);
    setNewIngredient(initialIngredientValues());
    ref.current.firstElementChild.focus();
    setUnsavedIng({
      ...unsavedIng,
      [index]: false,
    });
  };

  return (
    <>
      <label className="my-2 w-full block italic text-gray-700 text-xs">
        Lägg till ingrediens:
      </label>
      <li className="py-1 text-gray-700" ref={ref}>
        <input
          className="bg-transparent outline-none italic focus:bg-gray-200 w-1/6 inline text-sm font-bold border-b border-dotted"
          type="number"
          name="amount"
          value={newIngredient.amount}
          placeholder="2"
          onChange={handleChange}
          onKeyPress={handleEnter}
        />
        <input
          className="bg-transparent outline-none italic focus:bg-gray-200 inline w-1/6 text-sm font-bold border-b border-dotted"
          type="text"
          name="unit"
          value={newIngredient.unit}
          placeholder="dl"
          onChange={handleChange}
          onKeyPress={handleEnter}
        />
        <input
          className="bg-transparent outline-none italic focus:bg-gray-200 inline w-1/2 text-sm border-b border-dotted"
          type="text"
          name="ingredient"
          value={newIngredient.ingredient}
          placeholder="mjöl"
          onChange={handleChange}
          onKeyPress={handleEnter}
        />
        <ButtonBulletGreen className="ml-2 float-right" onClick={handleSubmit}>
          +
        </ButtonBulletGreen>
      </li>
    </>
  );
};

export default NewIngredient;
