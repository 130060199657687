import React, { useState } from "react";
import { ButtonGreen } from "../../elements/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import TrashCan from "./TrashCan";

const EditRecipeInstructions = (props) => {
  const { newRecipeData, setNewRecipeData, setEditing } = props;
  const [draggedItem, setDraggedItem] = useState();
  const [toDelete, setToDelete] = useState(false);
  const [showTrash, setShowTrash] = useState(false);
  const handleInstructionChange = (e) => {
    let newData = { ...newRecipeData };
    newData.instructions[e.target.id][e.target.name] = e.target.value;
    setNewRecipeData({ ...newData });
    setEditing(true);
  };

  const handleNewInstructions = () => {
    let newData = { ...newRecipeData };
    const number =
      newRecipeData.instructions.length >= 1
        ? newRecipeData.instructions[newRecipeData.instructions.length - 1]
            .number + 1
        : 1;
    newData.instructions.push({ number: number, instruction: "" });
    setNewRecipeData({ ...newData });
    setEditing(true);
  };

  const handleDrag = (e, i) => {
    e.dataTransfer.setData("text/html", e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, 0, 0);
    setDraggedItem(newRecipeData.instructions[i]);
  };

  const handleRelease = () => {
    if (!draggedItem) {
      return;
    }
    if (toDelete === true) {
      const filteredInstructions = newRecipeData.instructions.filter(
        (item) => item !== draggedItem
      );
      let newData = { ...newRecipeData };
      newData.instructions = filteredInstructions.map((inst, index) => ({
        instruction: inst.instruction,
        number: index + 1,
      }));
      setNewRecipeData({ ...newData });
    } else {
      setDraggedItem(null);
      let newData = { ...newRecipeData };
      newData.instructions = newRecipeData.instructions.map((inst, index) => ({
        instruction: inst.instruction,
        number: index + 1,
      }));
      setNewRecipeData(newData);
    }
    setToDelete(false);
    setDraggedItem();
    setShowTrash(false);
  };

  const handleSort = (i) => {
    if (!draggedItem) {
      return;
    }
    const draggedOverItem = newRecipeData.instructions[i];

    if (draggedItem === draggedOverItem) {
      return;
    }

    let items = newRecipeData.instructions.filter(
      (item) => item !== draggedItem
    );

    items.splice(i, 0, draggedItem);

    let newData = { ...newRecipeData };
    newData.instructions = items;
    setNewRecipeData({ ...newData });
    setEditing(true);
  };

  const handlePrepareDelete = () => {
    if (!draggedItem) {
      return;
    }
    setToDelete(true);
  };
  const handleCancelDelete = () => {
    setToDelete(false);
  };

  return (
    <>
      <label className="text-xl block w-full mb-4">Gör så här:</label>
      <ul className=" w-full">
        {newRecipeData.instructions &&
          newRecipeData.instructions.map((inst, i) => (
            <li
              className="w-full h-full "
              onDragOver={() => handleSort(i)}
              key={i}
            >
              <div
                id={i}
                draggable
                onMouseOver={() => setShowTrash(true)}
                onMouseLeave={() => setShowTrash(false)}
                onDragStart={(e) => handleDrag(e, i)}
                onDragEnd={handleRelease}
                className="table-cell align-text-top pr-6 w-14 pb-4 cursor-grab group active:cursor-grabbing"
              >
                <span
                  id="child"
                  className="rounded-full flex h-12 w-12 justify-center items-center bg-blue-500 text-white text-xl font-bold"
                >
                  <span id="grandchild" className="group-hover:hidden">
                    {inst.number}
                  </span>
                  <span id="grandchild" className="hidden group-hover:inline">
                    <FontAwesomeIcon id="grandgrandchild" icon={faSort} />
                  </span>
                </span>
              </div>

              <div className="table-cell align-top pb-6 w-full">
                <textarea
                  id={i}
                  name="instruction"
                  placeholder="Instruktioner för detta steg"
                  value={inst.instruction}
                  className="w-full outline-none note-pad"
                  onChange={handleInstructionChange}
                />
              </div>
            </li>
          ))}
        <TrashCan
          showTrash={showTrash}
          toDelete={toDelete}
          handleCancelDelete={handleCancelDelete}
          handlePrepareDelete={handlePrepareDelete}
        />
      </ul>
      <div className=" w-full">
        <div className="table-cell align-text-top pr-6 w-14 pb-4">
          <ButtonGreen
            className="rounded-full flex h-12 w-12 justify-center items-center text-white text-xl font-bold"
            onClick={handleNewInstructions}
          >
            +
          </ButtonGreen>
        </div>
        <div className="table-cell align-top pb-6 w-full"></div>
      </div>
    </>
  );
};

export default EditRecipeInstructions;
