import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const FilterRecipeData = (props) => {
  const { filter, setFilter } = props;

  const handleFilter = (e) => {
    setFilter(e.target.value);
  };

  return (
    <div className="px-4">
      <FontAwesomeIcon
        icon={faSearch}
        className="text-gray-500 absolute ml-2 mt-2"
      />
      <input
        type="text"
        name="filter"
        id="filter"
        onChange={handleFilter}
        value={filter}
        placeholder="Filtrera"
        className="w-full float-left outline-none focus:bg-white rounded-full  pl-8 border border-gray-300 bg-gray-200 focus:shadow text-sm leading-8"
      />
    </div>
  );
};

export default FilterRecipeData;
