import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Login from "./Login";
import Logo from "../logo.svg";
import "./Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faHeart,
  faPencilAlt,
  faUserCog,
} from "@fortawesome/free-solid-svg-icons";

const Header = (props) => {
  const { user, onSignInWithGoogle, onSignOut, userCanEdit } = props;
  let lastScrollY = 0;
  let ticking = false;
  const nav = useRef();
  const handleScroll = () => {
    lastScrollY = window.scrollY;

    if (!ticking) {
      window.requestAnimationFrame(() => {
        nav.current.style.height = `${100 - lastScrollY * 0.7}px`;
        ticking = false;
      });

      ticking = true;
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <>
      <div
        ref={nav}
        id="navbar"
        className="header fixed bg-blue-900 w-full shadow  text-white"
      >
        <div id="headerContainer" className="container h-full flex mx-auto">
          <div className="w-2/5 px-4">
            <Link to="/">
              <img src={Logo} alt="Storjala Logo" className="h-full" />
            </Link>
          </div>
          <div className=" w-2/5 flex align-middle items-center">
            <ul className="text-sm ">
              <li className="hover:underline inline pr-4 border-r mr-4">
                <Link to="/">Upptäck</Link>
              </li>
              <li className="hover:underline inline mr-4">
                <Link to="/alla">Bläddra</Link>
              </li>
            </ul>
          </div>

          <div className=" w-1/5 flex align-middle items-center text-right px-4">
            {!user && (
              <div className="w-full">
                <Login
                  className="float-right  text-2xl hover:text-blue-200"
                  onSignOut={onSignOut}
                  onSignInWithGoogle={onSignInWithGoogle}
                  user={user}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {user ? (
        <div className="w-full sub-header p-2 h-40 text-white bg-gray-800">
          <div className="container mx-auto flex">
            <div className="w-full px-4 py-1">
              {user && (
                <>
                  <nav className="text-sm inline-block">
                    <ul>
                      {userCanEdit() && (
                        <>
                          <li className="inline mr-8 group hover:underline">
                            <Link to="/edit/new">
                              <FontAwesomeIcon
                                className="mr-2 text-2xl group-hover:text-yellow-500 align-bottom"
                                icon={faPencilAlt}
                              />
                              <span className="hidden md:inline">
                                Nytt recept
                              </span>
                            </Link>
                          </li>
                          <li className="inline mr-8 group hover:underline">
                            <Link to="/user/recept">
                              <FontAwesomeIcon
                                className="mr-2 text-2xl group-hover:text-blue-500 align-bottom"
                                icon={faFileAlt}
                              />
                              <span className="hidden md:inline">
                                Mina recept
                              </span>
                            </Link>
                          </li>
                        </>
                      )}
                      <li className="inline mr-8 group  hover:underline">
                        <Link to="/user/favoriter">
                          <FontAwesomeIcon
                            className="mr-2 text-2xl group-hover:text-orange-500  align-bottom"
                            icon={faHeart}
                          />
                          <span className="hidden md:inline">
                            Mina favoriter
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                  <nav className="float-right inline-block">
                    <ul className="w-full">
                      <li className="inline ml-6 group  hover:underline">
                        <Link to="/user/settings">
                          <FontAwesomeIcon
                            className="text-2xl group-hover:text-blue-200  align-bottom"
                            icon={faUserCog}
                          />
                        </Link>
                      </li>
                      <li className="inline ml-6 group  hover:underline">
                        <Login
                          className=" hover:text-blue-200 align-bottom text-2xl"
                          onSignOut={onSignOut}
                          onSignInWithGoogle={onSignInWithGoogle}
                          user={user}
                        />
                      </li>
                    </ul>
                  </nav>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full sub-header -mb-4"></div>
      )}
    </>
  );
};

export default Header;
