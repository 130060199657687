import React, { useState } from "react";
import { useCategories } from "../utils/recipeFunctions";
import { Link } from "react-router-dom";
import Loader from "../elements/Loader";
import { ButtonGray } from "../elements/Buttons";

const HomeMenu = (props) => {
  const { recipeData } = props;
  const recipeCategories = useCategories(recipeData);
  const [numberOfCat, setNumberOfCat] = useState(5);

  const handleMoreCats = () => {
    setNumberOfCat(numberOfCat + 5);
  };
  if (!recipeData) {
    return <Loader />;
  }
  return (
    <div className="w-full shadow rounded bg-white inline-block align-top px-6 py-4">
      <h3 className="text-xl lg:text-2xl">Hitta recept</h3>
      <ul className="mb-4 text-xs lg:text-sm">
        <li className="hover:underline inline mr-4 lg:block">
          <Link to="/">Upptäck</Link>
        </li>
        <li className="hover:underline inline mr-4 lg:block">
          <Link to="/alla">Lista alla recept</Link>
        </li>
      </ul>
      <ul className="mb-4 text-xs lg:text-sm">
        <label className="text-lg block lg:text-xl mr-2">Kategorier</label>
        {recipeCategories &&
          recipeCategories.slice(0, numberOfCat).map((cat, i) => (
            <li
              key={i}
              className="capitalize lg:block inline-block rounded lg:rounded-none bg-blue-500 lg:text-black text-white  lg:bg-transparent mr-2 mb-2 lg:m-0 px-2 py-1 lg:p-0 hover:underline"
            >
              <Link to={`/kategori/${cat.category}`}>
                {`${cat.category} (${cat.numberOf})`}{" "}
              </Link>
            </li>
          ))}
        {recipeCategories.length > numberOfCat && (
          <ButtonGray className="lg:mt-4" onClick={handleMoreCats}>
            Ladda fler ...
          </ButtonGray>
        )}
      </ul>
    </div>
  );
};

export default HomeMenu;
