import React from "react";
import HomeMenu from "./HomeMenu";
import Loader from "../elements/Loader";

const Home = (props) => {
  const { recipeData, size } = props;

  if (!recipeData) {
    return <Loader />;
  }
  return (
    <>
      {size !== "full" ? (
        <>
          <div className="lg:w-1/5 w-full mb-6 px-4 lg:px-0 lg:pl-4">
            <HomeMenu recipeData={recipeData} />
          </div>

          <div
            id="main"
            className="lg:w-4/5 w-full lg:pl-6  inline-block align-text-top"
          >
            {props.children && props.children}
          </div>
        </>
      ) : (
        <div className="w-full">
          <div className="w-full block mb-6 px-4"></div>
          <div id="main" className=" w-full   block align-text-top">
            {props.children && props.children}
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
