import React, { useState } from "react";
import ListRecipes from "./ListRecipes";
import Loader from "../elements/Loader";
import { ButtonGray } from "../elements/Buttons";
import { usePopularRecipes } from "../utils/recipeFunctions";
import { faFireAlt } from "@fortawesome/free-solid-svg-icons";

const ListPopularRecipes = (props) => {
  const { userData, recipeData, numberOf, currentUserData } = props;
  const popularRecipeData = usePopularRecipes(recipeData, userData);
  const [numberOfPopular, setNumberOfPopular] = useState(numberOf);

  const handleMore = () => {
    setNumberOfPopular(numberOfPopular + numberOf);
  };

  if (!popularRecipeData) {
    return <Loader />;
  }
  return (
    <>
      <ListRecipes
        header="Populärt!"
        headerIcon={faFireAlt}
        currentUserData={currentUserData}
        recipeData={popularRecipeData.slice(0, numberOfPopular)}
      >
        {numberOf && numberOfPopular < popularRecipeData.length && (
          <ButtonGray
            className="mb-1 mt-6 h-8 w-auto lg:w-8  overflow-hidden border-blue-500 px-3 lg:px-0 hover:w-auto hover:px-3  rounded-full"
            onClick={handleMore}
          >
            <strong>+</strong> Fler populära recept ...
          </ButtonGray>
        )}
      </ListRecipes>
    </>
  );
};

export default ListPopularRecipes;
